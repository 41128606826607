<template>
  <div class="main">
  <Header/>
  <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span @click="$router.push('/')">标准规范</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  <div class="pdf-viewer">
    <!-- <pdf src="/static/ppp.pdf"></pdf> -->
    <iframe :src="pdfSrc" width="100%" align="middle"></iframe>
  </div>
  <Foot/>
  </div>
</template>

<script>
import Foot from "../components/Foot.vue";
import Header from "../components/Header.vue";
export default {
  data() {
    return {
      pdfSrc: "",
    };
  },
  components: { Header,Foot},
  computed: {},
  mounted() {
    // this.pdfSrc = `http://xltotal.xlteacher.cn${this.$route.query.pu}`;
    this.pdfSrc = this.$route.query.pu;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.main{
  width: 100%;
  min-height: 800px;
  background: #f8f8f8;
.pdf-viewer {
  min-height: calc(100vh - 80px);
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
  iframe {
    border: 0;
    min-height: calc(100vh - 90px);
  }
}
}
</style>
